//popup body
.bodyMainPop {
	margin: 0;
	@include dvu-font-regular($font-12);
	color: $grey-300d;

	&:hover {
		cursor: default;
	}

	.toast-notification-container {
		position: fixed;
		width: 50%;
		bottom: 0;
		transform: translate(50%, -50%);
		z-index: 9999999;
	}

	&.bodyAnagrafica {
		background-color: $grey-400d;
	}

	&.iframeRePrint {
		background-color: $white;
		border-radius: 6px;
		padding: 20px 30px;

		.reprintTitle {
			padding: 0;
			text-align: center;
			@include dvu-font-medium($font-16);
		}

		.reprintMessage, .reprintCheck, .reprintLeft, .reprintButtons {
			display: block;
			width: 100%;
			padding: 6px 0;

			.btnspacer {
				display: none;
			}

			.btnReprintCancel,
			.btnReprintOK {
				margin: 0 6px !important;
			}

			.btnReprintOK {
				float: right;
			}
		}
	}
	/*Specifics for WinnerOfTheDay, WinnerOfTheWeek, LastWinnings (Ultime vincite), coupon check and betslip pending popup windows, */
	&.iframeLWotd, &.iframeLWotw, &.iframeLW, &.iframeCC, &.iframeSR {
		overflow: hidden;
		line-height: 0;

		#aspnetForm {
			height: 100%;
			line-height: 0;
		}

		div[id$='tbl_DettagliSistema'] {
			.dgStyle {
				border-collapse: collapse;

				tr {
					td:first-child {
						width: 90px;
					}
				}
			}
		}

		.detScoVP,
		.detScoIG {
			color: $yellow-400d;
			white-space: nowrap;
		}

		.cellaSx {
			color: $black;
			height: 23px;
			font-weight: bold;
		}

		.Quota_New {
			color: $yellow-400d;
		}
	}

	&.iframeCC {

		#aspnetForm {
			height: auto;
		}
	}

	&.iframeSR {

		.SectionTitle {
			padding-top: 10px;
		}

		.dvuBetDetailPopup {
			height: 378px;
			overflow-y: hidden;
			width: 790px !important;
			margin: 12px 30px 30px;

			.tblPopup {
				border-collapse: collapse;
				padding: 0;
				border-spacing: 0;
				line-height: 14px;

				tr[id$='trAccettaRifiuta'] {
					.cellaDx {
						padding-bottom: 10px;
					}
				}
			}

			.dgStyle {
				.dgAItemStyle, .dgItemStyle {
					td {
						padding: 0;

						&:first-child {
							text-align: left;
						}
					}
				}
			}
		}
	}

	&.ifPopup {
		background-color: $white;
		width: 400px;
		height: 250px;
		padding: 0px 30px;

		form > div {
			width: 100%;
		}

		.NormalPageContent {
			background-color: $white;
		}

		.TopDX h3 {
			@include dvu-font-medium($font-16);
			text-align: center;
			padding: 12px 0;
		}

		.SectionTitle {
			color: $black;
			height: 23px;
			border-bottom: 1px solid $grey-150;
		}

		.cellaSx {
			color: $black;
			height: 23px;
			font-weight: bold;
		}

		[id$='btnAnnullaCoupon'] {
			margin-left: 39% !important;
			width: 192px !important;
		}
	}

	.LWimg,
	.CCimg {
		position: relative;
		height: 136px;
		background-image: url('../../images/banner_winners_2.jpg');
		width: 850px;
	}

	.CCimg {
		background-image: url('../../images/banner_coupon_check.jpg');
	}

	.stakedLW {
		@include dvu-font-medium($font-16);
		position: absolute;
		top: 28px;
		left: 346px;
		width: 215px;
		height: 80px;
		color: $white;
		line-height: 1;
		letter-spacing: 0.5px;

		.title {
			display: block;
			padding: 0px;
			margin: 12px auto 12px 12px;
			font-weight: 400;
		}

		.currency {
			display: inline-block;
			margin: 0px auto 0px 8px;
			padding: 0px;
			font-size: 30px;
			vertical-align: top;
		}

		.value {
			display: inline-block;
			margin: 0px auto 0px 12px;
			padding: 0px;
			max-width: 170px;
			font-size: 30px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.winLW {
		@include dvu-font-medium($font-16);
		position: absolute;
		top: 28px;
		left: 604px;
		width: 215px;
		height: 80px;
		color: $yellow-400d;
		line-height: 1;
		letter-spacing: 0.5px;

		.title {
			display: block;
			padding: 0px;
			margin: 12px auto 12px 12px;
			font-weight: 400;
		}

		.currency {
			display: inline-block;
			margin: 0px auto 0px 8px;
			padding: 0px;
			font-size: 30px;
			vertical-align: top;
		}

		.value {
			display: inline-block;
			margin: 0px auto 0px 12px;
			padding: 0px;
			max-width: 170px;
			font-size: 30px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.LWContent,
	.cpnDetails {
		margin: 47px 30px 30px 30px;
		height: 242px;
		width: 790px !important;
		overflow: hidden;
		line-height: 1.5;
	}

	.dvuTblSection {
		border-bottom: 1px solid $grey-100d;
	}
	/*End of region*/
	/*Specifics for CouponCheck popup window*/
	.cpnDetails {
		width: 850px;
		padding: 30px;
		margin-bottom: 15px;
		height: 237px;
	}

	#popUp_PC_recaptcha {
		margin: 0 auto;
		display: inline-block;
	}

	.UserPopUpSrc:not(.anagraficaUserPopup) {
		border-radius: 6px;
		margin: 12px 0;
		border: 1px solid rgba($grey-300d, 0.5);

		input[type="text"] {
			background-color: $white;
			color: $grey-300d;

			&:-internal-autofill-selected {
				-webkit-box-shadow: 0 0 0 100px $white inset !important;
				-webkit-text-fill-color: $grey-300d;
			}
		}

		.cellaSx {
			padding: 12px 6px 0 10px;
		}

		.cellaDx {
			padding: 12px 10px 0 0;
		}

		.cellaDx.Anagrafica {
			position: relative;
		}

		.cellaDx.Anagrafica > span {
			position: absolute;
			right: 24px;
			top: 4px;
		}

		input[type="submit"] {
			margin: 12px 10px;
		}

		img[id*='popUp'] {
			position: relative;
			top: 1px;
		}
	}

	.userGridView {
		max-height: 350px;

		&::-webkit-scrollbar {
			width: 2px;
		}

		&::-webkit-scrollbar-track {
			background: $grey-100d;
		}

		&::-webkit-scrollbar-thumb {
			height: 46px;
			border-radius: 8px;
			background-color: $yellow-200d;
		}
	}

	.mainLoginPopup .divLoginLogged {
		display: none;
	}
	//login popup page
	&.dvuLoginPopup {
		background-color: $grey-300d;
		color: $white;

		.mainLoginPopup {
			padding: 10px 0;
			height: 400px;
			width: 800px;

			.logo {
				background-image: url('../../images/Logo_slogan.svg');
				height: 78px;
				width: 215px;
				margin: 0px auto 20px auto;
			}

			.msg {
				border-radius: 6px;
				margin: 10px auto !important;
				text-align: center;
				padding: 5px;
				border: 1px solid $grey-150d;
				line-height: 18px;
				font-size: $font-14;
			}

			.tdRegister {
				text-align: center;
			}

			.RegLblUsr, .RegLblPwd, .RegLblPwdRep {
				display: none;
			}

			.RegTxtUsr, .RegTxtPwd, .RegTxtPwdRep {
				padding: 5px 0;
			}

			.pnlLogged .RegDivBtn {
				margin-top: 24px;
			}

			.pnlLogged .RegNewUserBtn {
				margin-top: 10px;
			}

			.pnlLogged .RegNewUser {
				display: none;
				position: absolute;
				right: 0;
				top: 0;
			}

			.pnlLogged .txtLogin {
				float: none;
				margin: 0 auto;
				border-radius: 3px;
			}

			.pnlLogged .btnLogin {
				@include dvu-button-green-small-m();
			}

			.pnlLogged .btnRegister {
				@include dvu-button-small-m();
			}

			.pnlLogged .btnLogin:hover {
				background-image: none;
			}

			.divRegMain {
				height: auto;
				position: relative;
				top: 0;
				right: 0;
			}

			.RegLostPwd {
				font-size: $font-11;
				margin: 0 auto;
				position: relative;
				top: 10px;
			}

			.divLoginCode {
				display: none;
				top: 50px;
				position: relative;
				width: 200px;
				margin: auto;

				#boxAntieffrazione {
					div {
						float: left;
						margin: 7px 3px;
					}

					input {
						width: 25px;
						text-align: center;
					}

					a {
						float: left;
					}
				}

				.btnLogin {
					margin-top: 6px;
				}
			}
		}
	}

	.dgStyle {
		.dgSubHdrStyle {
			@include dvu-font-medium($font-10);
			color: $grey-300d;
			height: 18px;

			th:first-child {
				text-align: left;
			}
		}

		.GridDetailsFooterStyle {
			font-weight: bold;
			height: 23px;
			border-top: 1px solid $grey-100d;
		}
	}

	&.Report {
		background-color: $grey-400d;
	}
}
/*--- REG.POPUP ---*/
.RiquadroPopRiserva {
	width: 100%;
	background: $white;
	border-radius: 6px;
	padding: 12px 60px 16px 60px;
	position: relative;

	.TopSX, .TopDX {
		height: 0;
	}

	.Cnt {
		color: $grey-300d;
		border: none;
		background: $white //coupon background overwrite
	}

	.divTitle {
		padding: 0;
		text-align: center;
		padding-bottom: 12px;

		H3 {
			@include dvu-font-medium($font-16);
		}

		#popupContactDettCloseS,
		#popupLWClose,
		#popupLWotdClose,
		#popupLWotwClose,
		#popupMMClose,
		#popupCCClose,
		#popupLGPClose,
		#popupMEXClose {
			cursor: pointer;
			position: absolute;
			top: 3px;
			right: 5px;
			width: 15px;
			height: 15px;
			background-color: $grey-300d;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			mask-position: center;
			mask-repeat: no-repeat;
			-webkit-mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			-webkit-mask-position: center;
			-webkit-mask-repeat: no-repeat;

			img {
				display: none;
			}
		}
	}

	.divContent {
		margin: 0;
		padding: 10px;
		width: 100%;
		vertical-align: top;
		//background-image: url('images/riq_popupRis_content_bg.png');
		background-repeat: no-repeat;
		background-position: right bottom;
	}

	.img {
		display: none;
	}
}

#divRegistrationProcess {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	background-color: transparent;
	opacity: 1;
	border-radius: 5px;

	&:before {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $black;
		z-index: 2;
		opacity: 0.5;
	}

	#popupRegistrationProcess {
		width: 600px;
		position: fixed;
		top: 262px;
		left: calc(50% - 300px);
		display: block;
		background-color: $white;
		z-index: 3;
		border-radius: 6px;

		.RiquadroPopRiserva {
			width: 100%;
			overflow: hidden;

			.Cnt {

				#btnClosePopupRegistrationProcess {
					@include dvu-button-green-small-l();
					text-transform: capitalize;
				}

				span[id*='lblMessageOfRegistrationBonusCode'] {
					padding-bottom: 10px;
				}

				span[id*='lblMessageOfRegistrationBonusCode'],
				span[id*='MessageOfRegistrationProcess'] {
					display: block;
					width: 95%;
					margin: 0 auto;
					@include dvu-font-regular($font-12);
					text-align: center;
					padding-top: 12px;
				}
			}
		}
	}
}

#divLoadingProcess {
	position: fixed;
	width: 100%;
	height: 100%;
	display: none;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	background-color: transparent;
	opacity: 1;

	&:before {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $black;
		z-index: 2;
		opacity: 0.5;
	}
}
/*Specifics for WinnerOfTheDay, WinnerOfTheWeek, LastWinnings (Ultime vincite), CouponCheck widgets popup windows*/
#popupLW,
#popupLWotd,
#popupLWotw,
#popupContactSR,
#popupMM,
#popupCC,
#popup {
	display: none;
	position: fixed;
	width: 850px;
	z-index: 12000;

	.RiquadroPopRiserva {
		width: 100%;
		padding: 0px;
		box-sizing: content-box;
		overflow: hidden;

		iframe {
			display: block;
		}

		.Cnt {
			padding: 0px;
		}

		.divTitle {
			padding: 0;
			margin: 0;

			H3 {
				@include dvu-font-medium($font-16);
				position: absolute;
				top: 136px;
				left: 0px;
				box-sizing: border-box;
				width: 100%;
				padding: 20px 0px 10px 0px;
				margin: auto;
				background-color: $white;
				line-height: 1.1;
				text-align: center;
				text-transform: lowercase;

				&:first-letter {
					text-transform: capitalize;
				}
			}

			#popupLWotdClose,
			#popupLWotwClose,
			#popupLWClose,
			#popupCCClose {
				background-color: $white;
			}
		}
	}
}
.popupLogin {
	display: none;
	position: fixed;
	width: 400px !important;
	z-index: 12000;

	.RiquadroPopRiserva {
		width: 100%;
		padding: 16px;
		box-sizing: content-box;
		overflow: hidden;

		.iframe {
			display: block;
		}

		.btnRecaptchaConfirm{
			width: 100% !important;
		}

		.Cnt {
			padding: 0px;
		}

		.divTitle {
			padding: 0;
			margin: 0;

			H3 {
				@include dvu-font-medium($font-16);
				box-sizing: border-box;
				width: 100%;
				padding: 0px 0px 16px 0px;
				margin: auto;
				background-color: $white;
				line-height: 1.1;
				text-align: center;
				text-transform: lowercase;
				color:#000;

				&:first-letter {
					text-transform: capitalize;
				}
			}
		}

		.styleRecaptcha{
			padding-bottom: 16px;

			& > div:first-of-type{
				margin: 0 auto;
			}
			
		}
	}
}
/*End of region*/
/*Specifics for CouponCheck popup window*/
#popupCC {

	.RiquadroPopRiserva {

		iframe {
			min-height: auto !important;
			height: 500px;
		}
	}
}

#popupContactSR {

	h3 {
		position: initial !important;
	}
}

#popupAnnullamento {
	display: none;
	position: fixed;
	width: 400px;
	height: 250px;
	z-index: 12000;
	font-size: 12px;
	line-height: 14px;
	right: 6px;
	top: 4px;
	position: absolute;
	color: #6fa5fd;
	font-weight: 700;

	.sezBtn {
		position: absolute;
		right: 0px;
		top: 2px;
		font-size: 0px;
		width: 30px;
		text-align: right;

		#popupAnnullamentoClose {
			img {
				display: none;
			}

			width: 16px;
			height: 16px;
			background-color: $grey-400d;
			mask-image: url('../../images/icons/pw365-iconpack/current/system/regular/solid/close.svg');
			display: block;
			float: right;
			margin-right: 3px;
		}

		a {
			cursor: pointer;
		}
	}

	#ifPopup {
		width: 400px !important;
		overflow: hidden;
		border-radius: 6px;
		height: 270px;
	}
}

.isHuman {
	text-align: center;
	margin-top: 55px;

	[id$='btnVerificaScommessa'] {
		display: block !important;
		margin: 18px auto 120px auto !important;
	}
}

.divCheckCpnDisclaimer {
	@include dvu-font-regular($font-12);
	padding: 0px 0px 0px 60px;
	margin: 0px 30px;
	height: 35px;
	line-height: 35px;
	background-image: url('../../images/check_cpn_europe.png');
	background-position: -3px -3px;
	background-repeat: no-repeat;
}

/*End of region*/
#iframeLW .bodyMainPop {
	overflow: hidden;
}

#backgroundPopup,
#backgroundPopupSR,
#backgroundPopupLW,
#backgroundPopupLWotd,
#backgroundPopupLWotw,
#backgroundPopupMM,
#backgroundPopupLGP,
#backgroundPopupMEX,
#backgroundPopupCC,
#backgroundPopupERB,
#backgroundPopupDett,
.backgroundPopupLogin{
	display: none;
	position: fixed;
	_position: absolute; /* hack for internet explorer 6*/
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: $black;
	z-index: 5000;
}

.bodyPopDett {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	margin: 0;
	padding: 0;
	background-image: none;
	background-color: #202020;
}

#popupContactDett {
	display: none;
	position: fixed;
	height: 384px;
	width: 450px;
	z-index: 12000;
	font-size: 12px;

	#divBody {
		height: 345px;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 17px 0 0;
		font-size: 12px;
	}

	.RiquadroPopDettSE {
		.Cnt {
			background-color: #202020;
			border-left: 1px solid #ffbb03;
			border-right: 1px solid #ffbb03;
			padding: 0 5px 0 5px;
		}

		.TopSX {
			height: 6px;
			padding-left: 6px;
		}

		.TopDX {
			height: 6px;
			padding-right: 6px;
		}

		.BtmSX {
			height: 6px;
			padding-left: 6px;
		}

		.BtmDX {
			height: 6px;
			padding-right: 6px;
		}

		.divTitle {
			padding: 0 0 0 4px;
			margin: 0 0 3px 0;
		}

		.divTitleDX {
			color: #FFFFFF;
			margin: 0;
			padding: 0 4px 0 0;
			font-size: 11px;
			height: 21px;
			line-height: 21px;
			font-weight: bold;

			#popupContactDettClose {
				position: absolute;
				right: 3px;
				top: 3px;
				cursor: pointer;

				img{
					display:none;
				}
			}

			#spanDataSE {
				margin: 0 5px 0 0;
				color: #ffbb03;
			}
		}
	}
}

.GridDetailsFooterStyle {
	color: #FFFFFF;
	font-weight: bold;
	height: 18px;
}

.popAnagraficaTBL {
	empty-cells: show;
	background-color: #4a4a4a;
}

.popAnagraficaTD {
	background-color: #4a4a4a;
	font-size: 11px;
	color: #ffffff;
	height: 23px;
	font-weight: bold;
	width: 50%;
}

.popAnagraficaTD .anagraficaDIVrow {
	position: relative;
	height: 23px;
}

.popAnagraficaTD .anagraficaDIVrow SPAN {
	display: block;
	position: absolute;
	left: 10px;
	top: 0;
	line-height: 23px;
	white-space: nowrap;
}

.popAnagraficaTD .anagraficaDIVrow INPUT {
	display: block;
	position: absolute;
	right: 10px;
	top: 0;
}

.msgErrorCouponAsyncDet, .msgOkCouponAsyncDet {
	position: relative;
	background-color: #ccc;
	border: 1px solid #FFF;
	color: #000;
	line-height: 15px;
	font-size: 12px;
	background-repeat: no-repeat;
	background-position: 13px 10px;
	padding: 10px 10px 10px 75px;
	margin: 25px;
	border-radius: 5px;
	min-height: 64px;
}

/*SQL Page Popup*/
div[id$="pnlSqlPagePopup"] {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba($black,0.5);
  color: $black;

  > .popup {
    background-color: $white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 640px;
    height: auto;
    max-height: 100%;
    border-radius: 6px;
    padding: 12px 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    overflow: hidden;

    > .content {
      overflow: auto;
      line-height: normal;
    }

    > .buttons {
      display: flex;
      flex-flow: row;
      gap: 8px;

      > input[type="button"] {
        @include dvu-button-green-small-m();
      }
    }
  }
}
